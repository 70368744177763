import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Process control`}</h4>
    <p>{`In semiconductor fabrication, process control involves precisely monitoring wafers, photomasks, and the overall chip manufacturing process to ensure consistency and low manufacturing error rates. Accordingly, process control tools are among the most essential and valuable semiconductor manufacturing equipment.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      